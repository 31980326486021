import React from 'react';
import TextPage, { TextPageSection } from '../templates/text-page';

const About = () => (
  <TextPage title="Exhibitions">
    <TextPageSection title="Upcoming exhibitions">
      <p>
        <b>31 October - 2 November, 2021</b>
        <br />
        Glasgow, Scotland
        <br />
        The Pipe Factory
        <br />
        42 Bain Street
        <br />
        Glasgow
        <br />
        G40 2LA
        <br />
        12-6pm
      </p>
      <p>
        <b>Spring 2022</b>
        <br />
        London, UK
        <br />
        TBC
        <br />
      </p>
      <p>
        <b>Spring 2022</b>
        <br />
        New York, USA
        <br />
        TBC
      </p>
      <p>
        <a
          href="https://dothegreenthing.com/newsletter/"
          target="_blank"
          rel="noreferrer"
        >
          Sign up
        </a>
        {' '}
        to hear about upcoming exhibitions.
      </p>
    </TextPageSection>
  </TextPage>
);

export default About;
